//fontSize
export const userContextFontSize = 27;
export const fontSizeChange = 5;

//lineHeight
export const userContextLineHeight = 1.5;
export const lineHeightChange = 0.25;
export const lineHeightMax = 2.75;

//letterSpacing
export const userContextLetterSpacing = 1;
export const letterSpacingChange = 1;
export const letterSpacingMax = 5;
