import { faL } from "@fortawesome/free-solid-svg-icons";
import React, { createContext, useEffect, useState } from "react";
import Login from "../pages/Login";

import { getWebTimeSpend } from "../dataFromServer";
import {
  userContextFontSize,
  userContextLetterSpacing,
  userContextLineHeight,
} from "./userContextConstants";

const UserContext = createContext();

function UserProvider(props) {
  const [currTime, setCurrTime] = useState(0);

  // const [section, setSection] = useState("शालेय अभ्यासक्रम");

  // const [showToolbar, setShowToolbar] = useState(false);

  // const specialCourseText='स्पेशल कोर्सेस';
  const sectionTheme = ["शालेय अभ्यासक्रम", "स्पेशल कोर्सेस", "बुकमार्क"];

  const [ttsMode,setTtsMode]=useState(false)

  const [section, setSection] = useState(sectionTheme[1]);

  const [showToolbar, setShowToolbar] = useState(false);

  const [profile, setProfile] = useState(() => {
    if (localStorage.getItem("profile")) {
      console.log("Profile is selected");
      return localStorage.getItem("profile");
    } else {
      console.log("profile is not selected");
      return "";
    }
  });

  const [marginIndex, setMarginIndex] = useState(15);

  const [fontSize, setFontSize] = useState(userContextFontSize);
  const [lineHeight, setLineHeight] = useState(userContextLineHeight);
  const [letterSpacing, setLetterSpacing] = useState(userContextLetterSpacing);

  const [login, setLogin] = useState(() => {
    if (
      localStorage.getItem("login") &&
      localStorage.getItem("login") === "true"
    ) {
      console.log("Login is true");
      return true;
    } else {
      console.log("Login is false");
      setProfile("");
      return false;
    }
  });

  const [checkBookmark, setCheckBookmark] = useState(false);

  const [hideSidebar, setHideSideBar] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);

  useEffect(() => {
    // getWebTimeSpend().then(d=>{
    //     d && console.log("GetWebTimeSpend-3",d.data.total_time);
    //     d && setCurrTime(t=>t+d.data.total_time);
    //         })
    //         .catch(e=>console.log("Error"+e));
    // const timer =  setInterval(() => {
    //     setCurrTime(currTime => currTime + 1); // <-- Change this line!
    //     console.log("Time has changed")
    // }, 1000);
    // return () => {
    //         clearInterval(timer);
    // };
  }, []);

  function changeBookmark(val) {
    setCheckBookmark(val);
  }

  // const [marginIndex, setMarginIndex] = useState(10);

  // const [fontSize, setFontSize] = useState(50);

  // const [login, setLogin] = useState(() => {
  //   if (
  //     localStorage.getItem("login") &&
  //     localStorage.getItem("login") === "true"
  //   ) {
  //     console.log("Login is true");
  //     return true;
  //   } else {
  //     console.log("Login is false");
  //     setProfile("");
  //     return false;
  //   }
  // });

  // const [checkBookmark, setCheckBookmark] = useState(false);

  // const [hideSidebar, setHideSideBar] = useState(false);
  // const [hideHeader, setHideHeader] = useState(false);

  useEffect(() => {
    // getWebTimeSpend().then(d=>{
    //     d && console.log("GetWebTimeSpend-3",d.data.total_time);
    //     d && setCurrTime(t=>t+d.data.total_time);
    //         })
    //         .catch(e=>console.log("Error"+e));
    // const timer =  setInterval(() => {
    //     setCurrTime(currTime => currTime + 1); // <-- Change this line!
    //     console.log("Time has changed")
    // }, 1000);
    // return () => {
    //         clearInterval(timer);
    // };
  }, []);


  function changeTtsMode(val){
    setTtsMode(val);
  }

  function changeBookmark(val) {
    setCheckBookmark(val);
  }

  function changeSetHideSideBar(val) {
    setHideSideBar(val);
  }

  function changeSetHideHeader(val) {
    setHideHeader(val);
  }

  function changeShowToolbar(val) {
    setShowToolbar(val);
  }

  function changeSection(val) {
    setSection(val);
  }

  function changeLogin(val) {
    setLogin(val);

    if (val == true) {
      localStorage.setItem("login", true);
    } else {
      localStorage.setItem("login", false);
    }
  }

  function changeProfile(val) {
    setProfile(val);

    if (val) {
      localStorage.setItem("profile", val);
    }
  }

  function changeMarginIndex(index) {
    setMarginIndex(index);
  }

  function changeFontSize(index) {
    setFontSize(index);
  }

  const changeLineHeight = (height) => {
    setLineHeight(height);
  };

  const changeLetterSpacing = (space) => {
    setLetterSpacing(space);
  };

  function changeTime(time) {
    setCurrTime(time);
  }

  const value = {
    section: section,
    changeSection: changeSection,
    login: login,
    changeLogin: changeLogin,
    profile: profile,
    changeProfile: changeProfile,
    marginIndex: marginIndex,
    changeMarginIndex: changeMarginIndex,
    fontSize: fontSize,
    changeFontSize: changeFontSize,
    lineHeight: lineHeight,
    changeLineHeight: changeLineHeight,
    letterSpacing: letterSpacing,
    changeLetterSpacing: changeLetterSpacing,
    showToolbar: showToolbar,
    changeShowToolbar: changeShowToolbar,
    checkBookmark: checkBookmark,
    changeBookmark: changeBookmark,
    changeSetHideSideBar: changeSetHideSideBar,
    changeSetHideHeader: changeSetHideHeader,
    hideHeader: hideHeader,
    hideSidebar: hideSidebar,
    currTime: currTime,
    changeTime: changeTime,
    sectionTheme: sectionTheme,
    ttsMode:ttsMode,
    changeTtsMode:changeTtsMode
  };

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
}

export { UserContext, UserProvider };
