import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import ImageLight from "../assets/img/SchoolChildren.jpeg";
import ImageDark from "../assets/img/SchoolChildren.jpeg";
import ChildStudying from "../assets/img/ChildStudyingYellow.png";
import ChildrenforWeb from "../assets/img/ChildrenForWeb.png";
import VopaHeading from "../assets/img/VopaHeading.png";
import VopaHeadingLogo from "../assets/img/VOPA_Logo.png";
import ChildrenStudying from "../assets/img/ChildrenStudyingRed.png";
import { GithubIcon, PeopleIcon, TwitterIcon } from "../icons";
import { Label, Input, Button, HelperText } from "@windmill/react-ui";
import { RightIcon } from "../icons";
// import {HeartIcon} from "../icons";
import { useState } from "react";
import { fa } from "faker/lib/locales";

import { UserContext } from "../context/UserContext";

import { startAuth, verifyOTP, getStudentList } from "../dataFromServer";
import { combineReducers } from "redux";

// import {login, startAuth} from '../redux/actions';

function Login() {
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [numberMode, setNumberMode] = useState(true);
  const [profileDetails, setProfileDetails] = useState("");

  // const handlecheckLogin=props.checkLogin;

  const userContext = useContext(UserContext);

  function handleSubmitPhone() {
    console.log("Before numberMode condition");

    if (numberMode) {
      console.log("ABout to enter startAuth");
      startAuth(number, false, null);
      setNumberMode(false);

      return;
    }
    console.log("ABout to enter verifyOTP");

    //  verifyOTP(number,otp).then(d=>console.log(d));
    //  const result= await verifyOTP(number,otp);

    //  console.log(result);
    //  if(result && result!='FAIL')
    //  {
    //   // handlecheckLogin(true);
    //   console.log("OTP is verified and token is sent");
    //   userContext.changeLogin(true);
    //   localStorage.setItem("login",true);
    //  }

    // setProfileDetails(verifyOTP(number,otp).then(d=>console.log(d)));
    // console.log(verifyOTP(number,otp));

    console.log("Number+OTP", number, otp);
    verifyOTP(number, otp)
      .then((d) => console.log("Profiles are", d))
      .catch((e) => console.log("ProfileError", e));

    const values = verifyOTP(number, otp);

    values &&
      values.then((d) => {
        console.log("The magic word is" + d);
        d && setProfileDetails(d);
      });

    values && setProfileDetails(values);

    // console.log(values.then(d=>console.log("The returned value is "+d)));

    // const getDetails=verifyOTP(number,otp);

    // getDetails.then(d=>console.log("D is ->"+d));

    // verifyOTP(number,otp).then(d=>console.log("D is ->"+d));
  }

  useEffect(() => {
    profileDetails &&
      console.log("profile details entered with value", profileDetails);

    if (profileDetails == "SUCCESS") {
      console.log("OTP is verified and token is sent");
      userContext.changeLogin(true);
      localStorage.setItem("login", true);
      //getStudentList();
    }
  }, [profileDetails]);

  useEffect(() => {
    setProfileDetails("");
  }, [otp]);

  return (
    <div className="flex items-center min-h-screen  bg-gray-50 dark:bg-gray-900">
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <div className="h-64 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              className="object-cover w-full h-full dark:hidden"
              src={ChildrenforWeb}
              alt="Office"
            />
            <img
              aria-hidden="true"
              className="hidden object-cover w-full h-full dark:block"
              src={ChildrenforWeb}
              alt="Office"
            />
            {/* <img
              aria-hidden="true"
              className="object-cover w-full h-full"
              src={ChildrenStudying}
              alt="Office"
            /> */}
          </div>
          <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div className="w-full">
              <div className="mx-auto w-full text-center">
                <img
                  src={VopaHeadingLogo}
                  className=" md:w-6/2 w-1/2 lg:w-9/12 mx-auto"
                />
                {/* <p className='dark:text-gray-200'>Vowels of the People Association</p> */}
              </div>
              <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200 mt-6">
                लॉगिन
              </h1>
              {numberMode ? (
                <div className="flex flex-wrap mt-1">
                  <div className="w-full lg:w-12/12 font-light">
                    <Label>
                      <span>आपला मोबाइल नंबर नमूद करा</span>

                      <Input
                        className="mt-1"
                        type="text"
                        placeholder=""
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        autoFocus
                      />
                      {/^[6-9]\d{9}$/.test(number) && number ? (
                        ""
                      ) : (
                        <HelperText valid={false} style={{ color: "#7D3AF2" }}>
                          कृपिया वैध नंबर लिहा{" "}
                        </HelperText>
                      )}
                    </Label>
                    {/* {if (number!=10)} <p className='text-red-600 text-xs'>Enter Correct Number</p> */}
                  </div>
                </div>
              ) : (
                <div className="flex flex-wrap mt-10">
                  <div className="w-full lg:w-12/12 font-light">
                    <Label>
                      <span>ओ. टी. पी. नमूद करा</span>

                      <Input
                        className="mt-1"
                        type="password"
                        placeholder=""
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        autoFocus
                      />
                      {/* {(!number||number.length==10) ? "":<HelperText valid={false}>Provide a valid Contact Number</HelperText>} */}
                    </Label>
                    {otp.length > 0 &&
                    profileDetails &&
                    profileDetails == "FAIL" ? (
                      <HelperText
                        className="text-red-600 text-xs mt-2"
                        style={{ color: "#7D3AF2" }}
                      >
                        Invalid OTP
                      </HelperText>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              <div className="mx-auto">
                <Button
                  className="mt-4 mx-auto"
                  block
                  size="large"
                  disabled={
                    (numberMode && !/^[6-9]\d{9}$/.test(number)) ||
                    (!numberMode && otp.length < 4)
                      ? true
                      : false
                  }
                  onClick={handleSubmitPhone}
                >
                  {numberMode ? "ओ. टी. पी. पाठवा" : "लॉगिन"}
                </Button>
              </div>
              {/* <HelperText valid={false} className="mt-10" style={{'color':'#7D3AF2'}}>
                *लॉगिन करण्यापूर्वी 'व्ही-स्कूल फ्री' मोबाइल ॲपवर नोंदणी करणे आवश्यक आहे
              </HelperText> */}
              {/* <div className="absolute bottom-0">
                <Button>Click here</Button>
              </div> */}
              {/* <div className="inline-block align-text-bottom align-bottom flex w-full bg-red-400">Test</div> */}
              <div className="text-center">
                <Button
                  icon={PeopleIcon}
                  size="small"
                  className="mt-10 hover:bg-purple-200 hover:border-purple-400 mx-auto bg-purple-100"
                  layout="link"
                >
                  <a href="https://hm.vschoolindia.com" target="_blank">
                    मुख्याध्यापक आणि अधिकाऱ्यांसाठी प्रवेश
                  </a>
                </Button>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Login;
